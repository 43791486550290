<template>
  <div class="check-box">
    <template v-if="staticChecked">
      <input
        type="checkbox"
        :id="id"
        :name="name"
        :checked="checked"
        :disabled="disabled"
        :indeterminate.prop="indeterminate"
      >
    </template>
    <template v-else>
      <input
        type="checkbox"
        :id="id"
        :name="name"
        :disabled="disabled"
        :true-value="trueValue"
        :false-value="falseValue"
        :checked="checked"
        :value="value"
        v-model="proxy"
        @change="onChange"
      >
    </template>
    <label
      @click="onLabelClick"
      class="label-box"
      :for="id"
    >
      <svg-icon
        iconClass="status_checkbox_checked_normal"
        v-if="checked"
        :disabled="!!disabled"
        size="20px"
      />
      <svg-icon
        iconClass="status_checkbox_checked_all_normal"
        v-if="indeterminate"
        size="20px"
      />
      <svg-icon
        iconClass="status_checkbox_unchecked_normal"
        v-if="!checked && !indeterminate"
        :disabled="!!disabled"
        size="20px"
      />
      <div
        class="text"
        :disabled="disabled"
      >{{ label }}</div>
    </label>
  </div>
</template>
<script>
export default {
  name: 'CheckBox',
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    modelValue: {
      type: [Boolean, String, Number, Object, Array],
      default: undefined,
    },
    id: {
      type: String,
      default() {
        // eslint-disable-next-line no-underscore-dangle
        return `checkbox-id-${this._uid}`;
      },
    },
    name: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number, Array, Object],
      default: undefined,
    },
    trueValue: {
      type: [Boolean, String, Number],
      default: true,
    },
    falseValue: {
      type: [Boolean, String, Number],
      default: false,
    },
    staticChecked: {
      type: Boolean,
      required: false,
      default: null
    },
    indeterminate: {
      type: Boolean,
      required: false,
      default: false
    },
    dropDownMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      proxyModel: null,
    };
  },
  computed: {
    proxy: {
      get() {
        return this.modelValue;
      },
      set(newModelValue) {
        this.proxyModel = newModelValue;
      },
    },
    checked() {
      if (this.staticChecked !== null) {
        return this.staticChecked;
      }

      if (this.dropDownMode) {
        return this.modelValue.some((item) => item.value === this.value.value);
      }

      if (Array.isArray(this.modelValue)) {
        return this.modelValue.indexOf(this.value) >= 0;
      }

      return this.modelValue === this.trueValue;
    },
  },
  methods: {
    onChange() {
      this.$emit('change', this.proxyModel);
      this.$emit('input', this.proxyModel);
    },
    onLabelClick(event) {
      if (this.dropDownMode) {
        event.preventDefault();
      }
    }
  },
};
</script>
<style lang="less" scoped>
.check-box {
  input[type="checkbox"] {
    display: none;
  }
  .svg-icon {
    margin: rem(2.5) rem(2.5);
    &.status_checkbox_unchecked_normal {
      color: #ADADAD;
      &:hover, &:active {
        color: #FFFFFF;
      }
    }
    &.status_checkbox_checked_normal {
      color: #2986FF;
      &:hover, &:active {
        color: #0066FF;
      }
    }
  }
  .label-box {
    display: flex;
    align-items: center;
    cursor: pointer;

    .text {
      &[disabled] {
        color: #4F4F4F;
      }
      font-size: 14px;
      align-self: center;
      margin-left: rem(10);
    }
  }
  ::v-deep svg{
    cursor: pointer;
  }
}
</style>
